/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import React from "react";
import {Container, HotkeyList, PageOffset} from 'components';
import {PageLayout as MDXLayout} from "components";
function _createMdxContent(props) {
  return React.createElement(React.Fragment, null, React.createElement(Container, null, React.createElement(HotkeyList)), "\n", React.createElement(PageOffset));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
